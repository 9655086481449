import { PAGINATION_SEARCH } from 'src/hooks/useUrlParamsPagination';
import { initRoutes } from 'src/utils/router';
import type {
    AdReportingObjective,
    AdReportingPlatform,
} from 'src/apollo/definitions/globalTypes';

export const ROUTES = initRoutes('/ad-reporting', {
    ROOT: {
        path: '/',
    },
    REPORTS: {
        path: '/reports',
        search: {
            ...PAGINATION_SEARCH,
            search: String,
            globalParticipantId: String,
            platform: value => value as AdReportingPlatform,
        },
    },
    REPORT: {
        path: '/reports/:reportId',
    },
    REPORT_OVERVIEW: {
        path: '/reports/:reportId/overview',
    },
    REPORT_CAMPAIGNS: {
        path: '/reports/:reportId/campaigns',
    },
    REPORT_AD_SETS: {
        path: '/reports/:reportId/ad-sets',
    },
    REPORT_ADS: {
        path: '/reports/:reportId/ads',
    },
    CAMPAIGNS: {
        path: '/campaigns',
        search: {
            ...PAGINATION_SEARCH,
            search: String,
            globalParticipantId: String,
            countries: value => value.split(','),
            objective: value => value as AdReportingObjective,
            platform: value => value as AdReportingPlatform,
        },
    },
    CAMPAIGN: {
        path: '/campaigns/:campaignId',
    },
    CAMPAIGN_OVERVIEW: {
        path: '/campaigns/:campaignId/overview',
    },
    CAMPAIGN_AD_SETS: {
        path: '/campaigns/:campaignId/ad-sets',
        search: {
            ...PAGINATION_SEARCH,
            search: String,
            countries: value => value.split(','),
        },
    },
    CAMPAIGN_ADS: {
        path: '/campaigns/:campaignId/ads',
        search: {
            ...PAGINATION_SEARCH,
            search: String,
            adSetId: String,
            countries: value => value.split(','),
        },
    },
    AD_SETS: {
        path: '/ad-sets',
        search: {
            ...PAGINATION_SEARCH,
            search: String,
            campaignId: String,
            globalParticipantId: String,
            countries: value => value.split(','),
            objective: value => value as AdReportingObjective,
            platform: value => value as AdReportingPlatform,
        },
    },
    AD_SET: {
        path: '/ad-sets/:adSetId',
    },
    AD_SET_OVERVIEW: {
        path: '/ad-sets/:adSetId/overview',
    },
    AD_SET_ADS: {
        path: '/ad-sets/:adSetId/ads',
        search: {
            ...PAGINATION_SEARCH,
            search: String,
            countries: value => value.split(','),
        },
    },
    ADS: {
        path: '/ads',
        search: {
            ...PAGINATION_SEARCH,
            search: String,
            adSetId: String,
            campaignId: String,
            globalParticipantId: String,
            countries: value => value.split(','),
            objective: value => value as AdReportingObjective,
            platform: value => value as AdReportingPlatform,
        },
    },
    AD: {
        path: '/ads/:adId',
    },
    AD_OVERVIEW: {
        path: '/ads/:adId/overview',
    },
});
