export const removeUrlQueryParams = (
    url: string,
    paramsToRemove?: string[]
) => {
    const ref = new URL(url);

    paramsToRemove = paramsToRemove ?? [...ref.searchParams.keys()];
    paramsToRemove.forEach(param => ref.searchParams.delete(param));

    return ref.toString();
};
