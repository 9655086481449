import { formatMessage } from '@theorchard/suite-frontend';
import { useLocation } from 'react-router-dom';
import type { SuitePlugin } from '@theorchard/suite-frontend';

export const DefaultTitlePlugin = {
    Wrapper: ({ children }) => {
        const uncontrollableRoutes = ['/account'];

        const { pathname } = useLocation();

        if (uncontrollableRoutes.some(route => route === pathname)) {
            document.title = formatMessage('pages.defaultTitle');
        }

        return <>{children}</>;
    },
} satisfies SuitePlugin;
