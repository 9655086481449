import { Grid } from './grid';
import { Subgrid } from './subgrid';

const GridComponents = Grid as typeof Grid & {
    Row: typeof Subgrid;
};

GridComponents.Row = Subgrid;

export { GridComponents as Grid };
export type { GridProps } from './grid';
