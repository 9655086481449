export const addUrlQueryParams = (
    url: string,
    paramsToAdd: Record<string, string | number | boolean>
) => {
    const ref = new URL(url);

    Object.entries(paramsToAdd).forEach(([key, value]) => {
        ref.searchParams.append(key, value.toString());
    });

    return ref.toString();
};
