import { template } from 'lodash';
import type { PathTo, Routes, RoutesConfig } from './types';
import type { SupportedUrlParams } from 'src/hooks/useSearchParams/useSearchParams';

const pathTemplate = (path: string) =>
    template(path, { interpolate: /:(\w+)/g });

export const initRoutes = <
    const P extends `/${string}`,
    const T extends RoutesConfig,
>(
    rootPath: P,
    config: T
): Routes<P, T> => {
    const routes: Record<
        string,
        {
            path: string;
            pathTo: PathTo<string>;
            search?: Record<string, (param: string) => SupportedUrlParams>;
            searchParams?: Record<string, string>;
        }
    > = {};

    for (const key in config) {
        const params = config[key];

        // remove multiple // occurences
        const path = `${rootPath}${params.path}`.replace(/\/{2,}/g, '/');

        routes[key] = {
            path: path,
            pathTo: pathTemplate(path),
        };

        if (params.search) {
            routes[key].search = params.search;
            routes[key].searchParams = Object.fromEntries(
                Object.keys(params.search).map(key => [key, key])
            );
        }
    }

    return routes as Routes<P, T>;
};
