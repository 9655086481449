import type { CheckPermission, Permission } from '../types';

export const checkPermission =
    (permissions: Permission[]): CheckPermission =>
    (resourceType, action) =>
        Boolean(
            permissions
                .filter(({ effect }) => effect === 'allow')
                .find(
                    permission =>
                        permission.resourceType === resourceType &&
                        permission.action === action
                )
        );
