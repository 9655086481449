import { initRoutes } from 'src/utils/router';
import { ROUTES as AD_REPORTING_ROUTES } from './ad-reporting/routes';
import { ROUTES as AUDIENCES_ROUTES } from './audiences/routes';
import { ROUTES as CONNECTIONS_ROUTES } from './connections/routes';
import { ROUTES as EMAIL_CAMPAIGNS_ROUTES } from './email-campaigns/routes';
import { ROUTES as PARTICIPANT_ROUTES } from './participant/routes';
import { ROUTES as ROSTER_ROUTES } from './roster/routes';
import { ROUTES as SEARCH_ROUTES } from './search/routes';

export const ROUTES = initRoutes('/', {
    AD_REPORTING: AD_REPORTING_ROUTES.ROOT,
    ARTIST: PARTICIPANT_ROUTES.ARTIST,
    AUDIENCES: AUDIENCES_ROUTES.AUDIENCES,
    SEARCH: SEARCH_ROUTES.SEARCH,
    CONNECTIONS: CONNECTIONS_ROUTES.CONNECTIONS,
    EMAIL_CAMPAIGNS: EMAIL_CAMPAIGNS_ROUTES.ROOT,
    ROSTER: ROSTER_ROUTES.ROSTER,
});
