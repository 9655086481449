import { Suspense, createElement } from 'react';
import { LoadingOverlay } from 'src/components/common';

export function withSuspence(
    component: React.LazyExoticComponent<React.ComponentType>
) {
    const Lazy = () => {
        return (
            <Suspense fallback={<LoadingOverlay />}>
                {createElement(component)}
            </Suspense>
        );
    };

    return Lazy;
}
