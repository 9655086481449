import { Flex } from './flex';
import { Column } from './flexColumn';
import { Row } from './flexRow';

const FlexComponents = Flex as typeof Flex & {
    Row: typeof Row;
    Column: typeof Column;
};

FlexComponents.Row = Row;
FlexComponents.Column = Column;

export { FlexComponents as Flex };
export type { FlexProps } from './flex';
