export const grayColor = {
    'gray-50': 'c-gray-50',
    'gray-100': 'c-gray-100',
    'gray-150': 'c-gray-150',
    'gray-200': 'c-gray-200',
    'gray-250': 'c-gray-250',
    'gray-300': 'c-gray-300',
    'gray-350': 'c-gray-350',
    'gray-400': 'c-gray-400',
    'gray-450': 'c-gray-450',
    'gray-500': 'c-gray-500',
    'gray-550': 'c-gray-550',
    'gray-600': 'c-gray-600',
    'gray-650': 'c-gray-650',
    'gray-700': 'c-gray-700',
    'gray-750': 'c-gray-750',
    'gray-800': 'c-gray-800',
    'gray-850': 'c-gray-850',
    'gray-900': 'c-gray-900',
    'gray-950': 'c-gray-950',
    'gray-1000': 'c-gray-1000',
};

export const grayBackground = {
    'gray-50': 'bg-gray-50',
    'gray-100': 'bg-gray-100',
    'gray-150': 'bg-gray-150',
    'gray-200': 'bg-gray-200',
    'gray-250': 'bg-gray-250',
    'gray-300': 'bg-gray-300',
    'gray-350': 'bg-gray-350',
    'gray-400': 'bg-gray-400',
    'gray-450': 'bg-gray-450',
    'gray-500': 'bg-gray-500',
    'gray-550': 'bg-gray-550',
    'gray-600': 'bg-gray-600',
    'gray-650': 'bg-gray-650',
    'gray-700': 'bg-gray-700',
    'gray-750': 'bg-gray-750',
    'gray-800': 'bg-gray-800',
    'gray-850': 'bg-gray-850',
    'gray-900': 'bg-gray-900',
    'gray-950': 'bg-gray-950',
    'gray-1000': 'bg-gray-1000',
};

export const midnightColor = {
    'midnight-50': 'c-midnight-50',
    'midnight-100': 'c-midnight-100',
    'midnight-150': 'c-midnight-150',
    'midnight-200': 'c-midnight-200',
    'midnight-250': 'c-midnight-250',
    'midnight-300': 'c-midnight-300',
    'midnight-350': 'c-midnight-350',
    'midnight-400': 'c-midnight-400',
    'midnight-450': 'c-midnight-450',
    'midnight-500': 'c-midnight-500',
    'midnight-550': 'c-midnight-550',
    'midnight-600': 'c-midnight-600',
    'midnight-650': 'c-midnight-650',
    'midnight-700': 'c-midnight-700',
    'midnight-750': 'c-midnight-750',
    'midnight-800': 'c-midnight-800',
    'midnight-850': 'c-midnight-850',
    'midnight-900': 'c-midnight-900',
    'midnight-950': 'c-midnight-950',
    'midnight-1000': 'c-midnight-1000',
};

export const midnightBackground = {
    'midnight-50': 'bg-midnight-50',
    'midnight-100': 'bg-midnight-100',
    'midnight-150': 'bg-midnight-150',
    'midnight-200': 'bg-midnight-200',
    'midnight-250': 'bg-midnight-250',
    'midnight-300': 'bg-midnight-300',
    'midnight-350': 'bg-midnight-350',
    'midnight-400': 'bg-midnight-400',
    'midnight-450': 'bg-midnight-450',
    'midnight-500': 'bg-midnight-500',
    'midnight-550': 'bg-midnight-550',
    'midnight-600': 'bg-midnight-600',
    'midnight-650': 'bg-midnight-650',
    'midnight-700': 'bg-midnight-700',
    'midnight-750': 'bg-midnight-750',
    'midnight-800': 'bg-midnight-800',
    'midnight-850': 'bg-midnight-850',
    'midnight-900': 'bg-midnight-900',
    'midnight-950': 'bg-midnight-950',
    'midnight-1000': 'bg-midnight-1000',
};

export const datavizColor = {
    'dataviz-1': 'c-data-1',
    'dataviz-2': 'c-data-2',
    'dataviz-3': 'c-data-3',
    'dataviz-4': 'c-data-4',
    'dataviz-5': 'c-data-5',
    'dataviz-6': 'c-data-6',
    'dataviz-7': 'c-data-7',
    'dataviz-8': 'c-data-8',
    'dataviz-9': 'c-data-9',
    'dataviz-10': 'c-data-10',
    'dataviz-11': 'c-data-11',
    'dataviz-12': 'c-data-12',
    'dataviz-13': 'c-data-13',
    'dataviz-14': 'c-data-14',
    'dataviz-15': 'c-data-15',
};

export const datavizBackground = {
    'dataviz-1': 'bg-data-1',
    'dataviz-2': 'bg-data-2',
    'dataviz-3': 'bg-data-3',
    'dataviz-4': 'bg-data-4',
    'dataviz-5': 'bg-data-5',
    'dataviz-6': 'bg-data-6',
    'dataviz-7': 'bg-data-7',
    'dataviz-8': 'bg-data-8',
    'dataviz-9': 'bg-data-9',
    'dataviz-10': 'bg-data-10',
    'dataviz-11': 'bg-data-11',
    'dataviz-12': 'bg-data-12',
    'dataviz-13': 'bg-data-13',
    'dataviz-14': 'bg-data-14',
    'dataviz-15': 'bg-data-15',
};

export const semanticColor = {
    info: 'c-info',
    danger: 'c-danger',
    warning: 'c-warning',
    success: 'c-success',
    revision: 'c-revision',
    primary: 'c-primary',
    secondary: 'c-secondary',
};

export const semanticBackground = {
    info: 'bg-info',
    danger: 'bg-danger',
    warning: 'bg-warning',
    success: 'bg-success',
    revision: 'bg-revision',
};

export const defaultColor = {
    white: 'c-white',
    black: 'c-black',
    current: 'c-current',
    transparent: 'c-transparent',
};

export const defaultBackground = {
    white: 'bg-white',
    black: 'bg-black',
    current: 'bg-current',
    transparent: 'bg-transparent',
};
