import QUERY from 'src/apollo/queries/identityById/identityById.gql';
import type { GraphqlClient } from '@theorchard/suite-frontend';
import type {
    IdentityByIdQuery as Query,
    IdentityByIdQueryVariables as Variables,
} from 'src/apollo/definitions/identityById';

export const getTenantRoles = async ({
    identityId,
    graphqlClient,
}: {
    identityId: string;
    graphqlClient: GraphqlClient;
}) => {
    const result = await graphqlClient.query<Query, Variables>({
        query: QUERY,
        variables: { identityId },
    });

    const tenantRoles = result.data.identityById?.tenantRoles.items ?? [];

    return tenantRoles;
};

export type TenantRole = Awaited<ReturnType<typeof getTenantRoles>>[number];
