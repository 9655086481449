type Variants = Record<string, string | Record<number | string, string>>;

type Properties<T extends Variants> = {
    [K in keyof T]: T[K] extends string ? boolean : keyof T[K];
};

export function classNameProps<T extends Variants>(
    variants: T,
    options?: { baseClassName?: string; defaultProps?: Partial<Properties<T>> }
) {
    return (props?: Partial<Properties<T>>, customClassName?: string) => {
        const classNames: string[] = [];

        if (options?.baseClassName) {
            classNames.push(options.baseClassName);
        }

        // Object assignment via destructuring does not skip undefined properties
        const properties = { ...options?.defaultProps, ...props };

        for (const [key, prop] of Object.entries(properties)) {
            // check if undefined value exists in default props
            const value = prop ?? options?.defaultProps?.[key];

            if (value === undefined) continue;

            const variant = variants[key];

            if (typeof variant === 'string') {
                if (value === true) {
                    classNames.push(variant);
                }
            } else if (typeof value === 'string' || typeof value === 'number') {
                classNames.push(variant[value]);
            }
        }

        if (customClassName) {
            classNames.push(customClassName);
        }

        return classNames.join(' ');
    };
}

export type ClassNameProps<T extends ReturnType<typeof classNameProps>> =
    Parameters<T>[0];
