import { classNameProps } from 'src/utils/classNameProps';
import { boxVariants } from '../variants/box';
import { flexBoxVariants } from '../variants/flex';
import type { ClassNameProps } from 'src/utils/classNameProps';

const { align, justify, gap, rowGap, columnGap } = flexBoxVariants;

export const getGridClassNames = classNameProps(
    { ...boxVariants, align, justify, gap, rowGap, columnGap },
    { baseClassName: 'grid-layout' }
);

export type GridClassNameProps = ClassNameProps<typeof getGridClassNames>;

const { alignSelf, flex, ...restBoxVariants } = boxVariants;

export const getSubgridClassNames = classNameProps(
    { ...restBoxVariants, align, gap, rowGap, columnGap },
    { baseClassName: 'grid-layout grid-layout__subgrid' }
);

export type SubgridClassNameProps = ClassNameProps<typeof getSubgridClassNames>;
