import { initRoutes } from 'src/utils/router';

export const ROUTES = initRoutes('/search', {
    SEARCH: {
        path: '/',
        search: {
            term: String,
        },
    },
});
