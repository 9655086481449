import type { TypePolicies } from '@apollo/client';

export const typePolicies: TypePolicies = {
    AudienceData: {
        merge: true,
    },
    GlobalParticipant: {
        keyFields: ['id'],
    },
    EmailCampaign: {
        keyFields: ['id'],
    },
    MetaAdAccount: {
        keyFields: ['id', 'userAdAccountId'],
    },
    TiktokAdAccount: {
        keyFields: ['id', 'userAdAccountId'],
    },
    MetaUserConnection: {
        keyFields: () => 'MetaUserConnection',
    },
    TiktokUserConnection: {
        keyFields: () => 'TiktokUserConnection',
    },
};
