import { getSubgridClassNames } from './classNames';
import type { SubgridClassNameProps } from './classNames';
import type { PolymorphicComponentPropsWithRef } from '../../types';

const defaultElement = 'div';

export type SubgridOwnProps = SubgridClassNameProps &
    Pick<
        React.CSSProperties,
        | 'height'
        | 'minHeight'
        | 'maxHeight'
        | 'inset'
        | 'top'
        | 'left'
        | 'right'
        | 'bottom'
        | 'cursor'
        | 'zIndex'
    >;

export type SubgridProps<E extends React.ElementType = typeof defaultElement> =
    PolymorphicComponentPropsWithRef<E, SubgridOwnProps>;

export const Subgrid = <E extends React.ElementType = typeof defaultElement>({
    as,

    alignContent,
    padding,
    paddingX,
    paddingY,
    borderRadius,
    position,
    overflow,
    background,

    align,
    gap,
    rowGap,
    columnGap,
    style,
    className,

    height,
    minHeight,
    maxHeight,
    inset,
    top,
    left,
    right,
    bottom,
    zIndex,
    cursor,
    innerRef,
    ...props
}: SubgridProps<E>) => {
    const Element = as ?? defaultElement;

    const classNames = getSubgridClassNames(
        {
            alignContent,
            padding,
            paddingX,
            paddingY,
            borderRadius,
            position,
            overflow,
            background,
            align,
            gap,
            rowGap,
            columnGap,
        },
        className
    );

    const styles = {
        height,
        minHeight,
        maxHeight,
        inset, // inset should be defined before other position properties because it overwrites them, even if `undefined`
        top,
        left,
        right,
        bottom,
        zIndex,
        cursor,
        ...style,
    };

    return (
        <Element
            ref={innerRef}
            className={classNames}
            style={styles}
            {...props}
        />
    );
};
