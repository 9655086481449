export const RESOURCE_TYPE_ACTIONS: {
    action: string;
    resourceType: string;
    roles: string[];
}[] = [
    {
        resourceType: 'audience',
        action: 'view',
        roles: ['audience_development_admin', 'audience_development_client'],
    },
    {
        resourceType: 'audience',
        action: 'create',
        roles: ['audience_development_admin', 'audience_development_client'],
    },
    {
        resourceType: 'fan_data_channel',
        action: 'view',
        roles: ['audience_development_admin', 'audience_development_client'],
    },
];
