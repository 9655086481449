import { createContext, useContext } from 'react';
import type { PermissionsPluginData } from './types';

export type PermissionsContextValue = Pick<
    PermissionsPluginData,
    'permissions' | 'tenantRoles'
> | null;

export const PermissionsContext = createContext<PermissionsContextValue>(null);

export const usePermissionsContext = () => {
    const context = useContext(PermissionsContext);

    if (context === null) {
        throw new Error(
            '`usePermissionsContext` hook must be used within `PermissionsContext.Provider`'
        );
    }

    return context;
};
