import { getPermissions } from './onAppDataLoad';
import { PermissionsSuiteWrapper } from './wrapper';
import type { PermissionsPluginData } from './types';
import type { SuitePlugin } from '@theorchard/suite-frontend';

export const PermissionsPlugin: SuitePlugin<PermissionsPluginData> = {
    name: 'PermissionsPlugin',
    onAppDataLoad: getPermissions,
    Wrapper: PermissionsSuiteWrapper,
};
