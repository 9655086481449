import { PAGINATION_SEARCH } from 'src/hooks/useUrlParamsPagination';
import { initRoutes } from 'src/utils/router';

export const ROUTES = initRoutes('/roster', {
    ROSTER: {
        path: '/',
        search: {
            ...PAGINATION_SEARCH,
            search: String,
            vendorId: Number,
            subaccountId: Number,
            countries: value => value.split(','),
        },
    },
});
