import type { SupportedUrlParams } from '../useSearchParams/useSearchParams';
import type { UrlParams } from '../useSearchParams/useUrlParamsState';

export const PAGINATION_SEARCH = {
    page: Number,
    limit: Number,
    orderBy: String,
};

export const useUrlParamsPagination = <
    T extends Record<
        keyof typeof PAGINATION_SEARCH,
        (param: string) => SupportedUrlParams
    >,
>(
    setUrlParams: (
        setter:
            | ((state: UrlParams<T>) => Partial<UrlParams<T>>)
            | Partial<UrlParams<T>>,
        options?: { append?: boolean; replace?: boolean }
    ) => void
) => {
    const handlePageChange = (page: number) => {
        setUrlParams(state => ({ ...state, page: page + 1 }));
    };

    const handlePageSizeChange = (size: number) => {
        setUrlParams(state => ({ ...state, limit: size, page: 1 }));
    };

    const handleOrderChange = (orderBy: string) => {
        setUrlParams(state => ({ ...state, orderBy }));
    };

    return { handlePageChange, handlePageSizeChange, handleOrderChange };
};
