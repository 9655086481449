import { removeUrlQueryParams, addUrlQueryParams } from 'src/utils/router';

const TIKTOK_REDIRECT_QUERY_PARAMS = ['auth_code', 'code', 'state'];
const GOOGLE_REDIRECT_QUERY_PARAMS = ['code', 'scope'];

export const onInit = () => {
    const url = new URL(window.location.href);

    const isRedirectedFromTiktok = TIKTOK_REDIRECT_QUERY_PARAMS.every(param =>
        url.searchParams.has(param)
    );
    const isRedirectedFromGoogle = GOOGLE_REDIRECT_QUERY_PARAMS.every(param =>
        url.searchParams.has(param)
    );

    if (isRedirectedFromTiktok) {
        const sanitizedUrl = removeUrlQueryParams(window.location.href, [
            'code',
        ]);
        window.history.replaceState(null, '', sanitizedUrl);
    }

    if (isRedirectedFromGoogle) {
        const accessToken = url.searchParams.get('code');

        let sanitizedUrl = removeUrlQueryParams(window.location.href, ['code']);

        if (accessToken) {
            sanitizedUrl = addUrlQueryParams(sanitizedUrl, {
                accessToken,
            });
        }
        window.history.replaceState(null, '', sanitizedUrl);
    }
};
