import { getFlexBoxClassNames } from './classNames';
import type { FlexBoxProps } from './classNames';
import type { PolymorphicComponentPropsWithRef } from '../../types';

export type FlexOwnProps = FlexBoxProps &
    Pick<
        React.CSSProperties,
        | 'width'
        | 'minWidth'
        | 'maxWidth'
        | 'height'
        | 'minHeight'
        | 'maxHeight'
        | 'inset'
        | 'top'
        | 'left'
        | 'right'
        | 'bottom'
        | 'cursor'
        | 'zIndex'
    >;

export const defaultElement = 'div';

export type FlexProps<E extends React.ElementType = typeof defaultElement> =
    PolymorphicComponentPropsWithRef<E, FlexOwnProps>;

export const Flex = <E extends React.ElementType = typeof defaultElement>({
    as,
    className,
    style,
    width,
    minWidth,
    maxWidth,
    height,
    minHeight,
    maxHeight,
    borderRadius,
    position,
    inset,
    top,
    left,
    right,
    bottom,
    zIndex,
    direction = 'column',
    wrap,
    flex,
    align,
    alignContent,
    alignSelf,
    justify,
    gap,
    rowGap,
    columnGap,
    padding,
    paddingX,
    paddingY,
    overflow,
    background,
    cursor,
    innerRef,
    ...props
}: FlexProps<E>) => {
    const Element = as ?? defaultElement;

    const classNames = getFlexBoxClassNames(
        {
            alignContent,
            alignSelf,
            flex,
            position,
            padding,
            paddingX,
            paddingY,
            overflow,
            background,
            borderRadius,

            direction,
            align,
            justify,
            wrap,
            gap,
            rowGap,
            columnGap,
        },
        className
    );

    const styles = {
        width,
        minWidth,
        maxWidth,
        height,
        minHeight,
        maxHeight,
        inset, // inset should be defined before other position properties because it overwrites them, even if `undefined`
        top,
        left,
        right,
        bottom,
        zIndex,
        cursor,
        ...style,
    };

    return (
        <Element
            ref={innerRef}
            className={classNames}
            style={styles}
            {...props}
        />
    );
};
