import { classNameProps } from 'src/utils/classNameProps';
import { boxVariants } from '../variants/box';
import { flexBoxVariants } from '../variants/flex';
import type { ClassNameProps } from 'src/utils/classNameProps';

export const getFlexBoxClassNames = classNameProps(
    { ...boxVariants, ...flexBoxVariants },
    { baseClassName: 'd-flex' }
);

export type FlexBoxProps = ClassNameProps<typeof getFlexBoxClassNames>;
