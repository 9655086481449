import './flex.scss';

export const flexBoxVariants = {
    direction: {
        row: 'flex-row',
        column: 'flex-column',
    },
    align: {
        start: 'align-items-start',
        end: 'align-items-end',
        center: 'align-items-center',
        stretch: 'align-items-stretch',
        baseline: 'align-items-baseline',
    },
    justify: {
        start: 'justify-content-start',
        end: 'justify-content-end',
        center: 'justify-content-center',
        between: 'justify-content-between',
        around: 'justify-content-around',
    },
    wrap: 'flex-wrap',
    gap: {
        A: 'gapA',
        0: 'gap0',
        5: 'gap5',
        10: 'gap10',
        15: 'gap15',
        20: 'gap20',
        25: 'gap25',
        30: 'gap30',
        35: 'gap35',
        40: 'gap40',
        45: 'gap45',
        50: 'gap50',
    },
    rowGap: {
        A: 'gapRowA',
        0: 'gapRow0',
        5: 'gapRow5',
        10: 'gapRow10',
        15: 'gapRow15',
        20: 'gapRow20',
        25: 'gapRow25',
        30: 'gapRow30',
        35: 'gapRow35',
        40: 'gapRow40',
    },
    columnGap: {
        A: 'gapColumnA',
        0: 'gapColumn0',
        5: 'gapColumn5',
        10: 'gapColumn10',
        15: 'gapColumn15',
        20: 'gapColumn20',
        25: 'gapColumn25',
        30: 'gapColumn30',
        35: 'gapColumn35',
        40: 'gapColumn40',
    },
};
